import {
  Outlet,
  Link,
  ScrollRestoration,
  useParams,
  useLocation,
} from "react-router-dom";

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Org } from "~types/data";
import useLocales from "~hooks/useLocales";
import { PATH_DASHBOARD } from "~routes/paths";
import React from "react";
import { useDoc } from "~hooks/queries/useDoc";
import Footer from "./Footer";
import Header from "./Header";

export default function Main() {
  const location = useLocation();
  const params = useParams<{ orgId: string }>();
  const orgId = params.orgId || "";
  const docQuery = useDoc<Org>(orgId || "-1", "organizations");
  const orgName = docQuery.data?.name || "Loading...";
  const orgDetails = PATH_DASHBOARD.orgs.view(orgId);
  const showOrgBanner = orgId && location.pathname !== orgDetails;

  const headerRef = React.useRef(null);

  const { t } = useLocales();

  return (
    <>
      <Header />
      <main className="mx-auto p-6">
        {showOrgBanner && (
          <div className="mx-auto max-w-[85rem]">
            <Link
              className="group block rounded-lg bg-gray-100 p-4 text-center transition-all duration-300 hover:bg-gray-200 dark:bg-white/10 dark:hover:bg-white/10"
              to={"/"}
            >
              <p className="me-2 inline-block text-sm text-gray-800 dark:text-neutral-200">
                {t("current.org.is", { name: orgName })}
              </p>
              <span className="inline-flex items-center justify-center gap-x-2 text-sm font-semibold text-blue-600 decoration-2 group-hover:underline dark:text-blue-500">
                {t("orgs.switch")}
                <ChevronRightIcon className="size-4 flex-shrink-0 rtl:rotate-180" />
              </span>
            </Link>
          </div>
        )}
        <div className="relative mx-auto max-w-[85rem] px-0 py-10 sm:px-6 lg:px-8 lg:py-14">
          <Outlet />
        </div>
        <ScrollRestoration elementRef={headerRef} />
      </main>
      <Footer />
    </>
  );
}
