// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import {
  //getFirestore /* connectFirestoreEmulator */,
  initializeFirestore,
  persistentMultipleTabManager,
  persistentLocalCache,
} from "firebase/firestore";
import { getPerformance } from "firebase/performance";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { FIREBASE_CONFIG, RECAPTCHA_SITE_KEY } from "~config";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const firebaseApp = initializeApp(FIREBASE_CONFIG);
export const DB = initializeFirestore(firebaseApp, {
  localCache: persistentLocalCache(
    /*settings*/ { tabManager: persistentMultipleTabManager() },
  ),
});

if (window.location.hostname === "localhost") {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = RECAPTCHA_SITE_KEY;
}
initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

export const AUTH = getAuth(firebaseApp);
export const perf = getPerformance(firebaseApp);
//export const DB = getFirestore(firebaseApp);
export const STORAGE = getStorage(firebaseApp);
export const PUPLIC_STORAGE = getStorage(firebaseApp, "gs://file-drive-public");
export const analytics = getAnalytics(firebaseApp);
// if (window.location.hostname === "localhost") {
//   connectFirestoreEmulator(DB, "localhost", 8080);
// }
