import { UserRole, UserStatus, User } from "~/types/data";
import type { DocumentData } from "firebase/firestore";

export function isPending(profile?: DocumentData | null): boolean {
  return profile?.status === UserStatus.Pending;
}

export function isAdmin(profile?: DocumentData | null): boolean {
  return profile?.role === UserRole.Admin;
}

export function getRole(profile?: User | null): UserRole {
  return profile?.role || UserRole.Unknown;
}

export function hasAccessBasedOnRole(
  userRole: UserRole,
  requiredRoles?: UserRole[],
): boolean {
  if (userRole === UserRole.Unknown) {
    return false;
  }
  if (requiredRoles === undefined) {
    return true;
  }
  return requiredRoles.includes(userRole);
}

export function getInitials(name?: string) {
  return name
    ? name
        .split(" ")
        .map((part) => part.charAt(0).toUpperCase())
        .join("")
    : "UK";
}
