import { MouseEventHandler } from "react";
import { useRegisterSW } from "virtual:pwa-register/react";
import useLocales from "~hooks/useLocales";

export default function UpdateBanner() {
  const { t } = useLocales();
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      console.log("SW Registered: ", r);
    },
    onRegisterError(error) {
      console.log("SW registration error", error);
    },
  });

  const close: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setNeedRefresh(false);
  };

  const refresh: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    void updateServiceWorker(true);
  };

  if (needRefresh) {
    return (
      <div
        id="ab-full-width-with-dismiss-button-on-blue-bg"
        className="fixed inset-x-0 left-0 top-0 z-50 bg-blue-600 text-center"
      >
        <div className="mx-auto max-w-[85rem] px-4 py-4 sm:px-6 lg:px-8">
          <div className="text-white">
            <p>{t("update.available")}</p>
            <a
              className="mr-2 font-medium underline decoration-2 hover:text-white/80"
              onClick={refresh}
              href="#"
            >
              {t("refresh")}
            </a>{" "}
            |{" "}
            <a
              className="ml-2 font-medium underline decoration-2 hover:text-white/80"
              onClick={close}
              href="#"
            >
              {t("cancel")}
            </a>
          </div>
        </div>
      </div>
    );
  }
  return null;
}
