// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/";
export const ROOT_MY_ACCOUNT = "/account";

// ----------------------------------------------------------------------

export const MY_ACCOUNT = {
  root: ROOT_MY_ACCOUNT,
  settings: path(ROOT_MY_ACCOUNT, "/settings"),
};
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  signIn: path(ROOTS_AUTH, "/sign-in"),
  signUp: path(ROOTS_AUTH, "/sign-up"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
  unverifiedEmail: path(ROOTS_AUTH, "/unverified-email"),
  pending: path(ROOTS_AUTH, "/pending"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  urlFactory: {
    list: (name: string, stringParams?: Record<string, string>) => {
      return (
        path(ROOTS_DASHBOARD, "/" + name + "/list") +
        "?" +
        new URLSearchParams(stringParams).toString()
      );
    },
  },
  general: {
    welcome: path(ROOTS_DASHBOARD, "/welcome"),
  },
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  users: {
    root: "/users",
    list: "/users/list",
    profile: "/user/profile",
    remove: "/users/remove",
    edit: (name: string) => `/users/${name}/edit`,
    view: (name: string) => `/users/${name}/view`,
  },
  profile: {
    edit: `/profile/edit`,
    view: `/profile`,
  },
  documents: {
    root: (orgId: string) => `/orgs/${orgId}/documents`,
    new: (orgId: string) => `/orgs/${orgId}/documents/new`,
    list: (orgId: string) => `/orgs/${orgId}/documents`,
    edit: (orgId: string, name: string) =>
      `/orgs/${orgId}/documents/${name}/edit`,
    view: (orgId: string, name: string) =>
      `/orgs/${orgId}/documents/${name}/view`,
  },
  orgs: {
    root: `/orgs`,
    new: `/orgs/new`,
    list: `/orgs/list`,
    edit: (orgId: string) => `/orgs/${orgId}/edit`,
    view: (orgId: string) => `/orgs/${orgId}/view`,
  },
  files: {
    root: path(ROOTS_DASHBOARD, "/files"),
    new: path(ROOTS_DASHBOARD, "/files/new"),
    list: path(ROOTS_DASHBOARD, "/files/list"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/files/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/files/${name}/view`),
  },
};
