// config
import { useState } from "react";
import { themeOptions } from "~/config";

// ----------------------------------------------------------------------

export default function useThemeSwitcher() {
  const [theme, setTheme] = useState(
    ((localStorage.theme as string) ||
      "system") as (typeof themeOptions)[number],
  );

  const handleThemeLanguage = (
    newTheme: (typeof themeOptions)[number],
  ): void => {
    if (newTheme === "system") {
      localStorage.removeItem("theme");
    } else {
      localStorage.theme = newTheme;
    }
    setTheme(newTheme);
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  return {
    onThemeChange: handleThemeLanguage,
    theme,
  };
}
