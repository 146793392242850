import { Trans } from "react-i18next";
import EShelfLogo from "~components/logos/EShelfLogo";

const Footer = () => {
  const date = new Date().getFullYear();
  return (
    <footer className="mt-auto py-2 text-center">
      <div className="mx-auto max-w-[85rem] px-4 sm:px-6 lg:px-8">
        <div className="mx-auto w-full max-w-5xl px-4 py-5 lg:pt-4 xl:px-0">
          <div className="inline-flex items-center">
            {/* <!-- Logo --> */}
            <EShelfLogo
              className="h-8 text-[#61873f] lg:h-10"
              direction="ltr"
            />

            {/* <!-- End Logo --> */}

            <div className="ms-5 border-s border-slate-700 ps-5">
              <p className="text-sm">
                <Trans
                  i18nKey="layout.copyright"
                  date={new Date().getFullYear()}
                >
                  © {{ date }}
                  <a
                    href="https://www.frontierwebservices.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Frontier Web Serivces
                  </a>
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
