import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    viewBox="0 0 830 220"
    {...props}
  >
    <g transform="translate(83 7)">
      <rect
        width={62.344}
        height={200}
        fill="rgba(210,219,237,0.01)"
        stroke="currentColor"
        strokeWidth={4}
        rx={0}
        ry={0}
        transform="translate(8 3)"
      />
      <rect
        width={50}
        height={30}
        fill="none"
        stroke="currentColor"
        strokeWidth={3}
        rx={0}
        ry={0}
        transform="translate(14.339 165.33)"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth={5}
        d="M14 18.394 64 18M14 28.394 64 28M14 38.394 64 38"
      />
    </g>
    <g transform="translate(0 7)">
      <rect
        width={62.344}
        height={200}
        fill="rgba(210,219,237,0.01)"
        stroke="currentColor"
        strokeWidth={4}
        rx={0}
        ry={0}
        transform="translate(8 3)"
      />
      <rect
        width={50}
        height={30}
        fill="none"
        stroke="currentColor"
        strokeWidth={3}
        rx={0}
        ry={0}
        transform="translate(14.339 165.33)"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth={5}
        d="M14 18.394 64 18M14 28.394 64 28M14 38.394 64 38"
      />
    </g>
    <g transform="rotate(-22 149.773 -369.862)">
      <rect
        width={62.344}
        height={200}
        fill="rgba(210,219,237,0.01)"
        stroke="currentColor"
        strokeWidth={4}
        rx={0}
        ry={0}
        transform="translate(8 3)"
      />
      <rect
        width={50}
        height={30}
        fill="none"
        stroke="currentColor"
        strokeWidth={3}
        rx={0}
        ry={0}
        transform="translate(14.339 165.33)"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth={5}
        d="M14 18.394 64 18M14 28.394 64 28M14 38.394 64 38"
      />
    </g>
    <text
      fill="currentColor"
      strokeWidth={0}
      dx={0}
      dy={0}
      fontSize={104}
      fontWeight={400}
      transform="translate(296.066 145.5)"
    >
      <tspan y={0}>FWS eShelf</tspan>
    </text>
  </svg>
);
export default SvgComponent;
