import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "./Loadable";
import SignIn from "./loadables/SignIn";
import SignUp from "./loadables/SignUp";

// layouts
import MainLayout from "~layouts/Main";
import AuthLayout from "~layouts/Auth";
// guards
import GuestGuard from "~guards/GuestGuard";
import AuthGuard from "~guards/AuthGuard";

// config
import { PATH_DASHBOARD } from "~routes/paths";

// Root
import App from "~App";

// Loaders
import { verifyLoader } from "~loaders/verifyLoader";
import documentListLoader from "~pages/documents/loaders/list";
// ----------------------------------------------------------------------

// AUTHENTICATION
const ForgotPassword = Loadable(
  lazy(() => import("~pages/auth/ResetPassword")),
);
const ConfirmSignUp = Loadable(lazy(() => import("~pages/auth/Verify")));
const Pending = Loadable(lazy(() => import("~pages/auth/Pending")));

const UnverifiedEmail = Loadable(
  lazy(() => import("~pages/auth/UnverifiedEmail")),
);

// MAIN
const HomePage = Loadable(lazy(() => import("~pages/Home")));

// orgs
const OrgList = Loadable(
  lazy(() => import("~pages/organizations/ListWrapper")),
);
const OrgView = Loadable(lazy(() => import("~pages/organizations/View")));
const OrgEdit = Loadable(lazy(() => import("~pages/organizations/Edit")));
const OrgNew = Loadable(lazy(() => import("~pages/organizations/Create")));

// documents

const DocumentList = Loadable(
  lazy(() => import("~pages/documents/ListWrapper")),
);
const DocumentView = Loadable(lazy(() => import("~pages/documents/View")));
const DocumentEdit = Loadable(lazy(() => import("~pages/documents/Edit")));
const DocumentNew = Loadable(lazy(() => import("~pages/documents/Create")));

// Users
const UsersList = Loadable(lazy(() => import("~pages/users/List")));
const UsersView = Loadable(lazy(() => import("~pages/users/View")));
const UsersEdit = Loadable(lazy(() => import("~pages/users/Edit")));
// My Profile
const ViewMyProfile = Loadable(lazy(() => import("~pages/profile/View")));
const EditMyPreferences = Loadable(lazy(() => import("~pages/profile/Edit")));

const Page500 = Loadable(lazy(() => import("~pages/Page500")));
const Page403 = Loadable(lazy(() => import("~pages/Page403")));
const Page404 = Loadable(lazy(() => import("~pages/Page404")));

export const routes = [
  {
    element: <App />,
    errorElement: <Page500 />,
    children: [
      {
        path: "auth",
        element: <AuthLayout />,
        children: [
          {
            path: "sign-in",
            element: (
              <GuestGuard>
                <SignIn />
              </GuestGuard>
            ),
          },
          {
            path: "sign-up",
            element: (
              <GuestGuard>
                <SignUp />
              </GuestGuard>
            ),
          },
          { path: "reset-password", element: <ForgotPassword /> },
          { path: "verify", element: <ConfirmSignUp />, loader: verifyLoader },
          { path: "unverified-email", element: <UnverifiedEmail /> },
          { path: "pending", element: <Pending /> },
        ],
      },

      // Main Routes
      {
        path: "*",
        children: [
          { path: "500", element: <Page500 /> },
          { path: "404", element: <Page404 /> },
          { path: "403", element: <Page403 /> },
          { path: "*", element: <Navigate to="/404" replace /> },
        ],
      },
      {
        path: "/",
        element: (
          <AuthGuard>
            <MainLayout />
          </AuthGuard>
        ),
        children: [
          { element: <HomePage />, index: true },
          // account
          {
            path: "profile",

            children: [
              { element: <ViewMyProfile />, index: true },
              { path: "edit", element: <EditMyPreferences /> },
            ],
          },
          {
            path: "orgs",
            children: [
              {
                element: <Navigate to={PATH_DASHBOARD.orgs.list} replace />,
                index: true,
              },
              {
                path: "list",
                element: <OrgList />,
              },
              {
                path: ":orgId/view",
                element: <OrgView />,
              },
              {
                path: ":orgId/edit",
                element: <OrgEdit />,
              },
              {
                path: "new",
                element: <OrgNew />,
              },
              {
                path: ":orgId/documents",
                children: [
                  {
                    element: <DocumentList />,
                    index: true,
                    loader: documentListLoader,
                  },
                  {
                    path: ":id/view",
                    element: <DocumentView />,
                  },
                  {
                    path: ":id/edit",
                    element: <DocumentEdit />,
                  },
                  {
                    path: "new",
                    element: <DocumentNew />,
                  },
                ],
              },
            ],
          },
          {
            path: "users",
            children: [
              {
                element: <Navigate to={PATH_DASHBOARD.users.list} replace />,
                index: true,
              },
              {
                path: "list",
                element: <UsersList />,
              },
              {
                path: ":id/edit",
                element: <UsersEdit />,
              },
              {
                path: ":id/view",
                element: <UsersView />,
              },
            ],
          },
        ],
      },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
];
