import { Link, useNavigate, useParams } from "react-router-dom";
import { Fragment, MouseEventHandler, useMemo, useState } from "react";
import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from "@headlessui/react";

import {
  Bars3Icon,
  XMarkIcon,
  ArrowLeftStartOnRectangleIcon,
  // UserCircleIcon,
  // AdjustmentsVerticalIcon,
} from "@heroicons/react/24/outline";
import useAuth from "~hooks/useAuth";
import { UserRole } from "~types/data";
import useLocales from "~hooks/useLocales";
import { useSignOut } from "~hooks/mutations/useSignOut";
import { PATH_AUTH, PATH_DASHBOARD } from "~routes/paths";
import { useSnackbar } from "notistack";
import React from "react";
import { getInitials } from "~utils/user";
import EShelfLogoNoText from "~components/logos/EShelfLogoNoText";
import LanguageDropdown from "~components/LanguageDropdown";
import ThemeSwitcher from "~components/ThemeSwitcher";

const dropdownLinks = [
  // {
  //   name: "account",
  //   to: "/profile",
  //   icon: UserCircleIcon,
  // },
  // {
  //   name: "settings",
  //   to: "/profile/edit",
  //   icon: AdjustmentsVerticalIcon,
  // },
  {
    name: "logout",
    to: "/auth/logout",
    icon: ArrowLeftStartOnRectangleIcon,
  },
];
const Header = () => {
  const params = useParams<{ orgId: string }>();
  const orgId = params.orgId || "";

  const headerRef = React.useRef(null);

  const signOutMutation = useSignOut();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { profile } = useAuth();
  const { t } = useLocales();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isAdmin = profile?.role === UserRole.Admin;
  const initials = getInitials(profile?.displayName);
  const navBarLinks = useMemo(() => {
    const menuItems = [
      {
        to: "/",
        label: "home",
      },
    ];
    if (orgId) {
      menuItems.push({
        to: PATH_DASHBOARD.orgs.view(orgId),
        label: "organization",
      });
      menuItems.push({
        to: PATH_DASHBOARD.documents.list(orgId),
        label: "documents",
      });
      menuItems.push({
        to: PATH_DASHBOARD.documents.new(orgId),
        label: "new.document.header.link",
      });
    }
    if (isAdmin) {
      menuItems.push({
        to: "/users",
        label: "users.list.page",
      });
    }
    return menuItems;
  }, [orgId, isAdmin]);

  const handleLogout: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    signOutMutation.mutate(undefined, {
      onSuccess: () => {
        navigate(PATH_AUTH.signIn, { replace: true });
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("Unable to logout!", { variant: "error" });
      },
    });
  };

  return (
    <>
      <div className="sticky top-0 z-50 flex max-w-[85rem] justify-end md:items-center  lg:mx-auto">
        <LanguageDropdown />
        <ThemeSwitcher />
      </div>
      <header
        ref={headerRef}
        className="sticky inset-x-0 top-7 z-50 flex w-full flex-wrap md:flex-nowrap md:justify-start"
      >
        <nav
          className="relative mx-2 flex w-full max-w-[85rem] rounded-xl border border-slate-200 bg-white px-5 py-2 md:flex md:items-center md:justify-between lg:mx-auto dark:border-slate-600 dark:bg-slate-800"
          aria-label="Global"
        >
          <div className="flex w-full items-center justify-between lg:w-auto lg:space-x-2 rtl:lg:space-x-reverse">
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">FWS eShelf</span>
                <EShelfLogoNoText className="h-16 w-auto text-[#61873f] lg:h-16" />
              </Link>
            </div>
            <Link
              className="flex-none text-xl font-semibold text-[#61873f]"
              to="/"
              aria-label={t("layout.company")}
            >
              {t("layout.company")}
            </Link>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-10 w-10" aria-hidden="true" />
              </button>
            </div>
          </div>
          <PopoverGroup className="hidden items-center lg:flex lg:gap-x-12">
            {navBarLinks.map((link) => {
              return (
                <Link
                  to={link.to}
                  key={link.to}
                  className="text-sm font-semibold leading-6"
                >
                  {t(link.label)}
                </Link>
              );
            })}

            <Popover className="relative">
              <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                <span className="inline-flex size-8 items-center justify-center rounded-full bg-gray-800 font-semibold leading-none text-white dark:bg-white dark:text-slate-800">
                  {initials}
                </span>
              </PopoverButton>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel
                  anchor="bottom end"
                  className="absolute -left-8 top-full z-50 mt-3 max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 dark:bg-slate-800"
                >
                  <div className="p-4">
                    <div>
                      <p className="mt-1 text-sm">{profile?.displayName}</p>
                      <p className="my-2 text-xs">{profile?.emailAddress}</p>
                      <hr className="mb-2 border-dashed border-slate-800 dark:border-white" />
                    </div>
                    {dropdownLinks.map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-1 rounded-lg p-2 px-0 text-sm leading-6 hover:bg-gray-50 dark:hover:bg-slate-600"
                      >
                        <div className="flex h-7 w-7 flex-none items-center justify-center rounded-lg  hover:group-hover:dark:bg-neutral-800">
                          <item.icon
                            className="h-4 w-4 text-gray-600  group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="flex-auto">
                          <Link
                            to={item.to}
                            onClick={
                              item.name === "logout" ? handleLogout : undefined
                            }
                            className="block font-semibold"
                          >
                            {t(item.name)}
                            <span className="absolute inset-0" />
                          </Link>
                        </div>
                      </div>
                    ))}
                    <hr className="m-2 border-dashed border-slate-800 dark:border-white" />
                    <p className="mt-4 text-xs text-gray-600 dark:text-gray-200">
                      {t("release.date", { date: __BUILD_DATE__ })}
                    </p>
                  </div>
                </PopoverPanel>
              </Transition>
            </Popover>
          </PopoverGroup>
        </nav>
        <Dialog
          className="relative z-50 flex w-screen lg:hidden "
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black/30 duration-300 ease-out data-[closed]:opacity-0 dark:bg-white/30"
          />
          <DialogPanel
            transition
            className="overflow-none fixed bottom-2 left-2 right-2 top-9 rounded-xl border border-gray-200 bg-white px-5 py-2 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 dark:border-gray-600 dark:bg-slate-800"
          >
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">{t("layout.company")}</span>
                <EShelfLogoNoText className="block h-16 w-auto text-[#61873f] lg:h-16" />
              </Link>
              <Link
                className="flex-none text-xl font-semibold text-[#61873f] lg:ml-2"
                to="/"
                aria-label={t("layout.company")}
              >
                {t("layout.company")}
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-10 w-10" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navBarLinks.map((link) => {
                    return (
                      <CloseButton
                        as={Link}
                        to={link.to}
                        key={link.to}
                        className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50  dark:hover:bg-slate-600"
                      >
                        {t(link.label)}
                      </CloseButton>
                    );
                  })}

                  <hr className="border-dashed border-slate-600 dark:border-white" />

                  {dropdownLinks.map((item) => (
                    <CloseButton
                      key={item.name}
                      as={Link}
                      to={item.to}
                      onClick={
                        item.name === "logout" ? handleLogout : undefined
                      }
                      className="block rounded-lg py-2 pl-3 pr-3 text-sm font-semibold leading-7  hover:bg-gray-50  dark:hover:bg-slate-600"
                    >
                      {t(item.name)}
                    </CloseButton>
                  ))}
                  <hr className="mb-2 border-dashed border-slate-600 dark:border-white" />
                  <p className="mt-4 text-xs dark:text-slate-200">
                    {t("release.date", { date: __BUILD_DATE__ })}
                  </p>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </>
  );
};

export default Header;
