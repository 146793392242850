import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { Lang, translations } from "./translations";
// config
import { defaultLang } from "~config";

void i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      [Lang.ar]: {
        translation: translations[Lang.ar],
      },
      [Lang.en]: {
        translation: translations[Lang.en],
      },
      [Lang.fr]: {
        translation: translations[Lang.fr],
      },
    },
    //lng: localStorage.getItem("i18nextLng") || defaultLang.value,
    fallbackLng: defaultLang.value,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
