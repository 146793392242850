/* eslint-disable @typescript-eslint/no-explicit-any */
import { Timestamp, WhereFilterOp } from "firebase/firestore";
import type { User as FireBaseUser } from "firebase/auth";

export enum UserStatus {
  Active = "active",
  Pending = "pending",
  Banned = "banned",
}
export enum UserRole {
  Admin = "admin",
  Editor = "editor",
  Unknown = "unknown",
}

type NotificationPreference = {
  mobile: boolean;
  email: boolean;
};

export type UserPreferences = {
  newOrder: NotificationPreference;
  orderStatusUpdate: NotificationPreference;
  newUser?: NotificationPreference;
};

export type InitialUser = {
  id?: string;
  emailAddress: string;
  displayName: string;
  phoneNumber?: string;
  role: UserRole;
  status?: UserStatus;
  isVerified?: boolean;
  created: Timestamp;
  lastUpdated: Timestamp;
  photoUrl?: string | File;
  preferences?: UserPreferences;
};

export type User = InitialUser & {
  id: string;
};

export type InitialDocument = {
  id?: string;
  note?: string;
  department: string;
  docType: string;
  pdf?: string;
  pdfDownloadURL?: string;
  images?: string[] | File[];
  dateReceived?: Date;
  active: boolean;
  created?: Timestamp;
  lastUpdated?: Timestamp;
  createdBy?: string;
  createdById?: string;
  previewFileName?: string;
  prevImageDownloadURL?: string;
};

export type Document = Omit<InitialDocument, "id"> & {
  id: string;
};

export type Department = {
  id?: string;
  name: string;
  documentTypes?: string[];
  created?: Timestamp;
  lastUpdated?: Timestamp;
};

export type InitialOrg = {
  id?: string;
  name: string;
  description?: string;
  phone?: string;
  email?: string;
  website?: string;
  contactPerson?: string;
  logo?: string | File;
  thumbDownloadURL?: string;
  builtInLogoId?: string;
  created?: Timestamp;
  lastUpdated?: Timestamp;
  departments?: Department[];
};

export type Org = InitialOrg & {
  id: string;
};

export type SortFilters = {
  order?: string;
  orderBy?: string;
};

export type AutoCompleteFilters = {
  keyword?: string;
  groupId?: string;
  rawQuery?: [string, WhereFilterOp, any][];
};
export type OrgFilters =
  | (SortFilters & {
      keyword?: string;
      active?: boolean;
    })
  | undefined;

export type FirebaseContextType = {
  isInitialized: boolean;
  user: FireBaseUser | null;
  profile: User | null;
};

export type DocFilters =
  | (SortFilters & Omit<DocFiltersState, "page" | "perPage">)
  | undefined;

export type DocFiltersState = {
  page: number;
  perPage: number;
  keyword?: string;
  active?: boolean;
  department?: string;
  docType?: string;
  dateReceivedFrom?: string | Date;
  dateReceivedTo?: string | Date;
  rawQuery?: [string, WhereFilterOp, any][];
};

export type UserFiltersState = {
  page: number;
  perPage: number;
  status?: UserStatus;
  role?: UserRole;
};

export type UserFilters =
  | (SortFilters & Omit<UserFiltersState, "page" | "perPage">)
  | undefined;
