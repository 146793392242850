import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AUTH } from "~/backend/firebase";

export function useSignOut() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => AUTH.signOut(),

    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["currentUser"] });
    },
  });
}
