import { createContext, ReactNode, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import type { User as FirebaseUser } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
// @types
import { User, FirebaseContextType } from "~types/data";
//
import { AUTH, DB } from "~backend/firebase";

// ----------------------------------------------------------------------

const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [profile, setProfile] = useState<User | null>(null);
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onAuthStateChanged(AUTH, async (_user) => {
        if (_user) {
          const userRef = doc(DB, "users", _user.uid);

          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            setProfile(docSnap.data() as User);
          }
        }
        setUser(_user);
        setIsInitialized(true);
      }),
    [],
  );

  return (
    <AuthContext.Provider
      value={{
        isInitialized,
        user,
        profile: profile || null,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
