import { Outlet } from "react-router-dom";
import EShelf from "~components/logos/EShelfLogoNoText";
import Footer from "./Footer";
import LanguageDropdown from "~components/LanguageDropdown";
import ThemeSwitcher from "~components/ThemeSwitcher";

export default function AuthLayout() {
  return (
    <>
      <main className="mx-auto w-full max-w-md p-6">
        <div className="flex justify-end md:items-center  lg:mx-auto">
          <LanguageDropdown />
          <ThemeSwitcher />
        </div>
        <div className="mt-2 rounded-xl border border-slate-200 bg-white shadow-sm dark:border-slate-600 dark:bg-slate-900">
          <div className="p-4 sm:p-7">
            <div className="mb-2 text-center">
              <EShelf
                className="mx-auto block h-20 w-auto text-[#61873f]"
                direction="ltr"
              />
            </div>
            <Outlet />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
