export enum Lang {
  ar = "ar",
  en = "en-US",
  fr = "fr-FR",
}

export const translations: Record<Lang, object> = {
  [Lang.ar]: {
    home: "الصفحة الرئيسية",
    "layout.company": "FWS eShelf",
    //"layout.copyright": "© {{date}} Frontier Web Services",
    "layout.copyright": "© {{date}} <2>Frontier Web Services</2>",
    "language.chooser.ar": "عربى",
    "language.chooser.en-US": "English",
    "language.chooser.fr-FR": "Français",
    "profile.menu.profile": "المستخدم",
    logout: "تسجيل خروج",
    "errors.general": "آسف، كان هناك خطأ!",
    "errors.required.fields": "الرجاء إكمال الحقول المطلوبة!",
    save: "حفظ",
    "file.format": "تنسيق الملف",
    back: "العودة",
    download: "تحميل",
    delete: "حذف",
    edit: "تحرير",
    restore: "استعادة",
    "edit.document.remove.existing.file": "إزالة الملف الحالي",
    "document.deleted.message":
      'تم حذف الوثيقة ، انقر فوق "استعادة" لاستعادتها',
    "delete.modal.title": "يرجى التأكيد",
    "delete.modal.body": "هل أنت متأكد من أنك تريد حذف الوثيقة؟",
    cancel: "إلغاء",
    "deleted.documents": "الوثائق المحذوفة",
    "welcome.user": "مرحبًا {{displayName}}!",
    ok: "موافق",
    "invalid.date": "تنسيق التاريخ غير صالح",
    "home.title": "ابحث عن الوثائق",
    search: "بحث",
    "no.result": "لم يتم العثور على نتائج ، يرجى محاولة استخدام كلمات مختلفة.",
    "deleted.documents.no.documents": "لا توجد وثائق",
    "drawer.advanced.search": "البحث المتقدم",
    "search.searchAll": "أدخل كلمة البحث",
    "advanced.search.title": "البحث المتقدم",
    "show.more": "عرض المزيد",
    "client.info.zg": "الزاوية الصوفية الغظفية",
    "client.info.presidency": "الرئاسة",
    "client.info.upr": "الاتحاد من اجل الجمهورية",
    attachments: "المرفقات",
    "no.attachments": "لا توجد مرفقات",
    "add.attachments": "أضف مرفقات",
    "add.attachments.title": "أضف مرفقات",
    "alerts.attachments.saved": "تم حفظ المرفقات بنجاح",
    "click.to.download": "اضغط للتحميل",
    "client.info.dev": "تطبيق تجريبي",
    signin: "تسجيل الدخول",
    "email.address": "البريد الإلكتروني",
    password: "كلمه السر",
    "create.account.text": "ليس لديك حساب؟",
    "create.account.link": "اشتراك",
    "forgot.password": "هل نسيت كلمة السر؟",
    "already.have.an.account.text": "هل لديك حساب؟",
    "already.have.an.account.link": "تسجيل الدخول",
    "sign.up": "اشتراك",
    "confirm.password": "تأكيد كلمة السر",
    "last.name": "اسم العائلة",
    "first.name": "الاسم",
    "full.name": "الاسم الكامل",
    "confirm.sign.up": "قم بتأكيد التسجيل",
    "sign.up.code": "الرمز",
    InvalidPasswordException: "كلمة السر لا تتوافق مع السياسة.",
    CodeMismatchException:
      "رمز التحقق الذي قدمته غير صالح ، يرجى المحاولة مرة أخرى.",
    "request.new.password.code": "أرسل لي رمز التحقق",
    "check.email.for.code":
      "يرجى الاطلاع على بريدك الإلكتروني للحصول على رمز استعادة كلمة السر وإدخاله أدناه.",
    "create.new.password": "أنشئ كلمة سر جديدة",
    "password.rest.instructions":
      "يرجى تقديم عنوان بريدك الإلكتروني ، وسوف نرسل لك رمز التحقق لإعادة تعيين كلمة السر الخاصة بك.",
    "confirm.sign.up.instructions":
      "يرجى الاطلاع على بريدك الإلكتروني للحصول على رمز التحقق وإدخاله أدناه ، لإكمال تسجيلك.",
    "all.fields.required": "جميع الحقول مطلوبة.",
    "password.mismatch": "يرجى التحقق مرة أخرى من كلمات السر التي أدخلتها.",
    "password.instructions":
      "متطلبات كلمة المرور: بحد أدنى 8 أحرف ، ورقم واحد على الأقل ، وحرف واحد كبير ، وحرف واحد صغير.",
    "email.required": "مطلوب عنوان البريد الإلكتروني.",
    UsernameExistsException:
      "يوجد حساب مرتبط بالبريد الإلكتروني المحدد بالفعل ، يرجى تسجيل الدخول.",
    UserNotFoundException: "المستخدم غير موجود.",
    NotAuthorizedException: "اسم المستخدم أو كلمة السر غير صحيحة.",
    NetworkError: "عذرا ، كان هناك خطأ في الاتصال ، يرجى المحاولة مرة أخرى.",
    "account.pending": "الحساب في انتظار الموافقة",
    "account.pending.instructions":
      "تم إنشاء حسابك بنجاح ، ستتمكن من تسجيل الدخول بعد موافقة مسؤول الموقع الخاص بك.",
    "try.again": "حاول مجددا",
    "user.list": "User List",
    "pending.users.list": "المستخدمون غير النشطين",
    "active.users.list": "المحررون",
    "admin.users.list": "المديرون",
    "see.more": "شاهد المزيد",
    "user.column.userStatus": "حالة المستخدم",
    "user.column.email": "البريد الإلكتروني",
    "user.column.fullName": "الاسم",
    "make.regular": "التغيير إلى محرر",
    "make.admin": "التغيير إلى مدير",
    "disable.user": "تعطيل المستخدم",
    "enable.user": "تنشيط المستخدم",
    UNCONFIRMED: "غير مؤكد",
    CONFIRMED: "مؤكد",
    FORCE_CHANGE_PASSWORD: "غير مؤكد",
    "manage.users": "ادارة المستخدمين",
    "pending.users.list.desc":
      "هذه قائمة بالمستخدمين ، الذين تم تسجيلهم مؤخرًا ، ولكن لا يزال يتعين تأكيدهم من قبل المدير ، والمستخدمين الذين تم إلغاء تنشيطهم من قبل المديرين.",
    "active.users.list.desc":
      "يمكن للمحررين تحرير كل المحتوى في التطبيق ، لكن لن يتمكنوا من إجراء تغييرات على المستخدمين الآخرين.",
    "admin.users.list.desc":
      "يمكن للمديرين فعل أي شيء يمكن للمحررين القيام به ، ويمكنهم إدارة المستخدمين الآخرين.",
    "user.list.empty":
      "ليس لديك حاليًا أي مستخدمين آخرين ، بمجرد تسجيل المستخدمين ، سيتم إدراجهم هنا.",
    "ERROR_auth/invalid-credential": "اعتماد تسجيل الدخول غير صالح",
    "ERROR_auth/weak-password": "كلمة مرور ضعيفة",
    "remember.me": "تذكرنى",
    "verify.email": "مرحبًا - لم يتبق سوى خطوة واحدة سريعة!",
    "verify.email.unverified":
      "مرحبا بكم في الفريق! لتأمين حسابك وفتح جميع ميزاتنا الرائعة، يرجى التحقق من عنوان بريدك الإلكتروني. لقد أرسلنا إليك بريدًا إلكترونيًا سريعًا يتضمن التعليمات - تحقق من بريدك الوارد الآن.",
    "reset.password": "اعد ضبط كلمه السر",
    "reset.password.instructions":
      "يرجى تقديم عنوان بريدك الإلكتروني، وسنرسل لك رابط التحقق لإعادة تعيين كلمة المرور الخاصة بك.",
    "reset.password.send.link.button": "إعادة تعيين كلمة المرور",
    "reset.password.email.sent.success.instructions":
      "عظيم! تم إرسال بريد إلكتروني يحتوي على تعليمات حول كيفية إعادة تعيين كلمة المرور الخاصة بك إلى عنوان البريد الإلكتروني الذي قدمته.",
    "create.new.password.instructions":
      "أدخل كلمة مرور جديدة أدناه لحساب البريد الإلكتروني المحدد بـ {{email}}.",
    "ERROR_auth/expired-action-code": "الكود منتهي الصلاحية.",
    "create.new.password.error":
      "آه لا! الرابط غير صالح. حاول إعادة تعيين كلمة المرور مرة أخرى.",
    "auth/invalid-action-code":
      "الرجاء المحاولة مرة أخرى ، رابط التحقق لم يعد صالحًا.",
    "verify.email.success":
      "شكرا على التحقق من عنوان بريدك الإلكتروني ، اضغط على الرابط أدناه للوصول إلى حسابك.",
    "verify.email.resend.success":
      "يرجى التحقق من حساب البريد الإلكتروني الخاص بك للحصول على رابط جديد للتحقق من عنوان بريدك الإلكتروني ، وإذا لم تتمكن من العثور عليه ، فتحقق من مجلد الرسائل غير المرغوب فيها.",
    "verify.email.instructions":
      " مرحبًا بك من جديد!  لضمان تجربة آمنة ومخصصة ، يرجى النقر على الزر أدناه للتحقق من عنوان بريدك الإلكتروني.",
    "verify.email.button": "تحقق من بريدك الإلكتروني!",
    "verify.email.resend": "أرسل لي رابطًا جديدًا!",
    "access.dashboard": " استخدم التطبيق",
    "create.new.password.success":
      "ممتاز! تم تحديث كلمة المرور الخاصة بك بنجاح.",
    organizations: "مؤسسات",
    "my.organizations.add": "إضافة مؤسسة جديدة",
    "item.delete.one.success": "تم حذف عنصر واحد بنجاح!",
    "item.delete.multiple.success": "تم حذف {{number}} عناصر بنجاح!",
    "department.add.success": "تمت إضافة القسم بنجاح!",
    "document.type.add.success": "تمت إضافة نوع المستند بنجاح!",
    "document.type.delete.success": "تم حذف نوع المستند بنجاح!",
    "general.no.data": "لا يوجد بيانات.",
    departments: "أقسام",
    "new.department": "إضافة قسم جديد",
    "enter.new.department": "أدخل قسمًا جديدًا",
    "enter.new.document.type": "أدخل نوع مستند جديد",
    "add.new.org": "إضافة مؤسسة جديدة",
    "add.new.org.heading": "هل أنت جاهز لإضافة مؤسسة جديدة؟",
    "add.new.org.subheading": "أخبرنا بالمعلومات الخاصة بمؤسستك أدناه.",
    "add.new.org.form.name": "الاسم (مطلوب)",
    "add.new.org.form.email": "البريد الإلكتروني",
    "add.new.org.form.phone": "رقم الهاتف",
    "add.new.org.form.website": "موقع الكتروني",
    "add.new.org.form.contact.person": "الشخص المسؤول",
    "add.new.org.form.about": "حول",
    "add.new.org.form.button": "إضافة مؤسسة",
    "org.enter.valid.name": "أدخل اسمًا صالحًا",
    "view.org.subheading": "معلومات",
    "drag.drop.instructions":
      "اسحب وأفلت بعض الملفات هنا، أو انقر لتحديد الملفات",
    "drag.drop.instructions.new.org":
      "(سيتم قبول الصور بصيغة *.jpeg و *.png فقط)",
    "drag.drop.dropping": "اسحب وأفلِ الملفات هنا",
    "file-too-large": "خطأ: الملف كبير جداً.",
    "file-invalid-type": "خطأ: نوع الملف غير صالح.",
    "org.saved.success": "تم حفظ معلومات المنظمة بنجاح",
    "update.org": "تحديث معلومات المنظمة",
    "update.org.heading": "جاهز لتحديث معلومات منظمتك؟",
    "update.org.subheading": "حدث معلومات منظمتك أدناه",
    "update.org.button": "تحديث المعلومات",
    logo: "شعار",
    "ERROR_auth/network-request-failed": "فشل طلب الشبكة",
    "ERROR_auth/too-many-requests":
      "تم تعطيل الوصول إلى هذا الحساب مؤقتًا بسبب العديد من محاولات تسجيل الدخول الفاشلة. يمكنك استعادته فورًا عن طريق إعادة تعيين كلمة المرور الخاصة بك أو يمكنك المحاولة مرة أخرى لاحقًا.",
    "password.required": "كلمة المرور مطلوبة",
    "users.list.page": "المستخدمون",
    account: "حساب",
    settings: "الإعدادات",
    "select.an.avatar": "اختر صورة رمزية لمنظمتك",
    "or.upload.logo": "أو قم بتحميل شعار منظمتك",
    refresh: "تحديث",
    "update.available": "يتوفر تحديث جديد للتطبيق",
    "update.available.desc":
      "اضغط على `تحديث` للحصول على آخر تحديث ، أو انقر فوق `إلغاء` لتجاهل هذا التحديث.",
    "add.new.doc": "إضافة مستند جديد",
    "add.new.doc.heading": "إضافة مستند جديد",
    "add.new.doc.subheading": "قدم معلومات المستند أدناه.",
    "add.new.doc.form.button": "حفظ التغييرات",
    "add.new.doc.form.department.select": "القسم",
    "add.new.doc.form.doc.type": "نوع المستند",
    "add.new.doc.form.note": "ملاحظة اختيارية",
    "add.new.doc.form.date.recieved": "تاريخ الاستلام",
    active: "نشط",
    "upload.files": "تحميل الملفات",
    "select.department": "اختر قسمًا",
    "doc.enter.valid.department": "خطأ: القسم مطلوب.",
    "select.document.type": "اختر نوع المستند",
    "doc.enter.valid.doc.type": "خطأ: نوع المستند مطلوب.",
    "file.and.size.label": "{{name}} - {{size}} كيلوبايت.",
    inactive: "غير نشط",
    documents: "المستندات",
    "view.pdf": "تحميل",
    update: "تحديث",
    "update.doc": "تحديث المستند",
    "update.doc.heading": "تحديث معلومات المستند",
    "update.doc.subheading": "قم بإجراء التغييرات أدناه واضغط على `تحديث`",
    "doc.saved.success": "تم حفظ معلومات المستند بنجاح.",
    "update.document.upload.warning.title": "لا يمكن تحميل صور/مسح ضوئي إضافية",
    "update.document.upload.warning.description":
      "لا يمكن إضافة الصور/المسح الضوئي إلا عند إنشاء مستند جديد.",
    "error.code.404": "404",
    "error.message.404": "عذرا حدث خطأ ما",
    "error.message.404.details": "عذرًا ، لم نتمكن من العثور على صفحتك.",
    "common.back.to.home": "العودة إلى الصفحة الرئيسية",
    "error.message.403": "ممنوع",
    "error.message.403.details": "ليس لديك إذن للوصول إلى هذا المورد.",
    "error.message.500": "خطأ داخلي في الخادم",
    "error.message.500.details":
      "واجه الخادم خطأ غير متوقع. حاول مرة أخرى لاحقا.",
    "new.document.header.link": "وثيقة جديدة",
    "current.org.is": "المؤسسة الحالية هي: {{name}}",
    "orgs.switch": "عرض جميع المؤسسات",
    "choose.a.date.range": "اختر نطاق التاريخ",
    "no.results": "عذرا لم يتم العثور على نتائج!",
    "no.results.action": "حاول تعديل معايير البحث الخاصة بك.",
    "view.all.documents": "عرض جميع المستندات",
    "must.include.at.least.one.file": "يجب تضمين ملف واحد على الأقل",
    "add.type": "إضافة نوع",
    "add.department": "إضافة قسم",
    "user.ban": "حظر",
    "user.deactivate": "تعطيل",
    "user.activate": "تفعيل",
    "user.make.admin": "تعيين كمسؤول",
    "user.make.editor": "تعيين كمحرر",
    admin: "مسؤول",
    editor: "محرر",
    pending: "قيد الانتظار",
    banned: "محظور",
    unknown: "غير معروف",
    "delete.document": "تأكيد حذف المستند",
    "delete.document.description": "بمجرد حذف المستند، لا يمكن استعادته.",
    "release.date": "إصدار {{date}}",
    "I accept the<1>Terms and Conditions</1>": "أقبل <1>الشروط والأحكام</1>",
    "theme.switcher.light": "وضع الضوء",
    "theme.switcher.dark": "الوضع الداكن",
    "theme.switcher.system": "وضع النظام",
    "ERROR_auth/firebase-app-check-token-is-invalid.":
      "غير مصرح : رمز الأمان غير صالح.",
    archived: "مُؤرشف",
    next: "التالي",
    previous: "السابق",
    organization: "المؤسسة",
  },
  [Lang.en]: {
    home: "Home",
    "welcome.back": "Welome back! \n {{name}}",
    "home.title": "Welcome to FWS eShelf",
    "home.access.dashboard": "Access the dashboard",
    "home.access.dashboard.admin":
      "The dashboard allows you to manage orders, locations and other categories",
    "home.access.dashboard.dispatcher":
      "The dashboard allows you to manage orders, locations and other categories",
    "home.access.dashboard.driver":
      "The dashboard allows you to view and respond to customer orders",
    "home.access.dashboard.store-owner":
      "The dashboard gives you an overview of your transactions",
    "home.access.dashboard.distributor":
      "The dashboard gives you an overview of your transactions",
    "home.access.sign.in.text":
      "To access the dashboard, you need to sign in first.",
    "home.access.sign.in": "Sign In",
    "layout.company": "FWS eShelf",
    "layout.copyright": "© {{date}} <2>Frontier Web Services</2>",
    "language.chooser.ar": "عربى",
    "language.chooser.en-US": "English",
    "language.chooser.fr-FR": "Français",
    "profile.menu.profile": "Profile",
    logout: "Sign Out",
    "errors.general": "Sorry, there was an error!",
    "errors.required.fields": "Please complete required fields!",
    save: "Save",
    "file.format": "File format",
    back: "Back",
    download: "Download",
    delete: "Delete",
    edit: "Edit",
    restore: "Restore",
    "edit.document.remove.existing.file": "Remove existing file",
    "document.deleted.message":
      "This document has been deleted, click 'Restore' to get it back",
    "delete.modal.title": "Please confirm",
    "delete.modal.body": "Are you sure you want to delete this document?",
    cancel: "Cancel",
    "deleted.documents": "Deleted documents",
    "welcome.user": "Welcome {{displayName}}!",
    ok: "Ok",
    "invalid.date": "Invalid Date Format",
    search: "Search",
    "no.result": "No results found, please try different keywords.",
    "deleted.documents.no.documents": "No deleted documents.",
    "drawer.advanced.search": "Advanced search",
    "search.searchAll": "Enter a keyword",
    "advanced.search.title": "Advanced search",
    "show.more": "Show more",
    "client.info.zg": "",
    "client.info.presidency": "The presidency",
    "client.info.upr": "UPR",
    attachments: "Attachments",
    "no.attachments": "no attachments",
    "add.attachments": "Add attachments",
    "add.attachments.title": "Add attachments",
    "click.to.download": "Click to download",
    "client.info.dev": "Experimental App",
    signin: "Sign in",
    "email.address": "Email Address",
    password: "Password",
    "create.account.text": "Don't have an account?",
    "create.account.link": "Sign Up",
    "forgot.password": "Forgot password?",
    "already.have.an.account.text": "Already have an account?",
    "already.have.an.account.link": "Sign in",
    "sign.up": "Sign up",
    "confirm.password": "Confirm Password",
    "last.name": "Last Name",
    "first.name": "First Name",
    "full.name": "Full name",
    "confirm.sign.up": "Confirm Sign Up",
    "sign.up.code": "Code",
    InvalidPasswordException: "Password does not conform to policy.",
    CodeMismatchException:
      "Invalid verification code provided, please try again.",
    "request.new.password.code": "Send me verification code",
    "check.email.for.code":
      "Please check your email for password reset code and enter it below.",
    "reset.password": "Reset your password",
    "reset.password.instructions":
      "Please provide your email address, we will send you a verification link to reset your password.",
    "reset.password.send.link.button": "Send Me Password Reset Instructions",
    "reset.password.email.sent.success.instructions":
      "Great! an email with instructions on how to reset your password was sent to the Email address you provided.",
    "confirm.sign.up.instructions":
      "Please check your email for verification code and enter it below, to complete your sign up.",
    "all.fields.required": "All fields are required.",
    "password.mismatch": "Please double check the passwords you entered.",
    "password.instructions":
      "Password policy: minimum of 8 characters, at least one number, one uppercase letter, one lowercase letter, and one special character.",
    "email.required": "Email address is required.",
    UsernameExistsException:
      "An account with the given email already exists, please sign in.",
    UserNotFoundException: "User does not exist.",
    NotAuthorizedException: "Incorrect username or password.",
    NetworkError: "Sorry, there was a connection error, please try again.",
    "account.pending": "Account pending approval",
    "account.pending.instructions":
      "Your account was created successfully, you will be able to sign in upon approval by your site administrator.",
    "try.again": "Try again",
    "user.list": "User List",
    "pending.users.list": "Inactive Users",
    "active.users.list": "Editors",
    "admin.users.list": "Administrators",
    "see.more": "See More",
    "user.column.userStatus": "User Status",
    "user.column.email": "Email Address",
    "user.column.fullName": "Name",
    "make.regular": "Switch to editor",
    "make.admin": "Switch to admin",
    "disable.user": "Disable user",
    "enable.user": "Enable user",
    UNCONFIRMED: "Unconfirmed",
    CONFIRMED: "Confirmed",
    FORCE_CHANGE_PASSWORD: "Unconfirmed",
    "manage.users": "Manage users",
    "pending.users.list.desc":
      "This is a list of users, that recently signed up, but still need to be confirmed by an admin, and the ones that were deactivated by admins.",
    "active.users.list.desc":
      "Editors can edit all content in the app, but will not be able to make changes to other users.",
    "admin.users.list.desc":
      "Administrators can do anything editors can do, and they can manage other users.",
    "user.list.empty":
      "You currently have no other users, once users sign up, they will be listed here.",
    "error.not.found":
      "Sorry, page not found, return to the <0> home page</0>.",
    "auth/invalid-action-code":
      "Please try again, the verification link is no longer valid.",
    "auth/invalid-email": "Invalid Email.",
    "auth/user-not-found": "User Not Found.",
    "auth/weak-password": "Password is weak.",
    "auth/wrong-password": "Error! double check your password.",
    "auth/network-request-failed": "Network request failed.",
    "auth/email-already-in-use":
      "An account with this Email address already exists, please login.",
    "verify.email": "Welcome Onboard - Just One Quick Step Left!",
    "verify.email.success":
      "Thanks for verifying your Email address, click the link below to access your account.",
    "verify.email.resend.success":
      "Please check your Email account for a new link to verify your Email address, if you can't find it, check your spam folder.",
    "verify.email.instructions":
      "Welcome back!  To ensure a secure and personalized experience, please click the button below to verify your email address.",
    "verify.email.button": "Verify your Email!",
    "verify.email.resend": "Send me a new link!",
    "create.new.password": "Create a new password",
    "create.new.password.error":
      "Oh no! The link is invalid, please try reseting the password again!",
    "create.new.password.instructions":
      "Enter a new password below for the account identified by {{email}}.",
    "create.new.password.success":
      "Nice! your password was successfully updated.",
    "verify.email.unverified":
      "Welcome to the team!  To secure your account and unlock all our awesome features, kindly verify your email address. We've sent you a quick email with instructions - check your inbox now.",
    "access.dashboard": "Access the app",
    "coming.soon": "Coming Soon",
    "ERROR_auth/invalid-credential": "Invalid login credentials",
    "ERROR_auth/weak-password": "Weak password",
    "ERROR_auth/expired-action-code": "Code has expired.",
    "remember.me": "Remember me",
    organizations: "Companies",
    "my.organizations.add": "Add a new company",
    "item.delete.one.success": "One item was deleted successfully!",
    "item.delete.multiple.success":
      "{{number}} items were deleted successfully!",
    "department.add.success": "Department added with success!",
    "document.type.add.success": "Document type added with success!",
    "document.type.delete.success": "Document type deleted with success!",
    "general.no.data": "No data found.",
    departments: "Departments",
    "new.department": "Add new department",
    "enter.new.department": "Enter a new department",
    "enter.new.document.type": "Enter a new document type",
    "add.new.org": "Adding new org",
    "add.new.org.heading": "Ready add a new organization?",
    "add.new.org.subheading":
      "Tell us information about your organization below.",
    "add.new.org.form.name": "Name (Required)",
    "add.new.org.form.email": "E-mail Address",
    "add.new.org.form.phone": "Phone Number",
    "add.new.org.form.website": "Website",
    "add.new.org.form.contact.person": "Person in charge",
    "add.new.org.form.about": "About",
    "add.new.org.form.button": "Add Organization",
    "org.enter.valid.name": "Enter a valid name",
    "view.org.subheading": "Info",
    "drag.drop.instructions":
      "Drag 'n' drop some files here, or click to select files",
    "drag.drop.instructions.new.org":
      "(Only *.jpeg and *.png images will be accepted)",
    "drag.drop.dropping": "Drop the files here...",
    "file-too-large": "Error: File too large.",
    "file-invalid-type": "Error: invalid file type.",
    "org.saved.success": "Organization info saved successfully",
    "update.org": "Update organization info",
    "update.org.heading": "Ready to update your organization info?",
    "update.org.subheading": "Update your organization info below",
    "update.org.button": "Update Info",
    logo: "Logo",
    "ERROR_auth/network-request-failed": "Network request failed",
    "ERROR_auth/too-many-requests":
      "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
    "password.required": "Password required.",
    "users.list.page": "Users",
    account: "Account",
    settings: "Settings",
    "select.an.avatar": "Select a business avatar",
    "or.upload.logo": "Or upload your organization logo",
    refresh: "Refresh",
    "update.available": "A new app update is available",
    "update.available.desc":
      " Hit `Refresh` to get the latest update, or click `Cancel` to ignore this update.",
    "add.new.doc": "Add new document",
    "add.new.doc.heading": "Adding a new document",
    "add.new.doc.subheading": "Provide document information below.",
    "add.new.doc.form.button": "Save changes",
    "add.new.doc.form.department.select": "Department",
    "add.new.doc.form.doc.type": "Document type",
    "add.new.doc.form.note": "An optinal note",
    "add.new.doc.form.date.recieved": "Date recieved",
    active: "Active",
    "upload.files": "Upload files",
    "select.department": "Select a department",
    "doc.enter.valid.department": "Error: a department is required.",
    "select.document.type": "Select a document type",
    "doc.enter.valid.doc.type": "Error: a document type is required.",
    "file.and.size.label": "{{name}} - {{size}} MB.",
    inactive: "Inactive",
    documents: "Documents",
    "view.pdf": "Download",
    update: "Update",
    "update.doc": "Updating document",
    "update.doc.heading": "Update document information",
    "update.doc.subheading": "Make change below and hit `Update`",
    "doc.saved.success": "Document information saved successfully.",
    "update.document.upload.warning.title":
      "Cannot upload additional images/scans",
    "update.document.upload.warning.description":
      "Images/scans can only be added when creating a new document.",
    "error.code.404": "404",
    "error.message.404": "Oops, something went wrong.",
    "error.message.404.details": "Sorry, we couldn't find your page.",
    "common.back.to.home": "Back to Homepage",
    "error.message.403": "Forbidden",
    "error.message.403.details":
      "You don't have permission to access this resource.",
    "error.message.500": "Internal Server Error",
    "error.message.500.details":
      "The server encountered an unexpected error. Please try again later.",
    "new.document.header.link": "New Document",
    "current.org.is": "Current organization is: {{name}}",
    "orgs.switch": "View all organizations",
    "choose.a.date.range": "Choose a date range",
    "no.results": "No results found.",
    "no.results.action": "Refine your search.",
    "view.all.documents": "View all documents",
    "must.include.at.least.one.file": "Must include at least one file",
    "add.type": "Add type",
    "add.department": "Add department",
    "user.ban": "Ban",
    "user.deactivate": "Deactivate",
    "user.activate": "Activate",
    "user.make.admin": "Make Admin",
    "user.make.editor": "Make Editor",
    admin: "Admin",
    editor: "Editor",
    pending: "Pending",
    banned: "Banned",
    unknown: "Unknown",
    "delete.document": "Confirm Document Deletion",
    "delete.document.description":
      "Once a document is deleted, it cannot be restored.",
    "release.date": "Release {{date}}",
    "I accept the<1>Terms and Conditions</1>":
      "I accept the <1>Terms and Conditions</1>",
    "theme.switcher.light": "Light mode",
    "theme.switcher.dark": "Dark mode",
    "theme.switcher.system": "System mode",
    "ERROR_auth/firebase-app-check-token-is-invalid.":
      "Unauthorized : Invalid security token.",
    archived: "archived",
    next: "Next",
    previous: "Previous",
    organization: "Organization",
  },
  [Lang.fr]: {
    home: "Accueil",
    "layout.company": "FWS eShelf",
    "layout.copyright": "© {{date}} <2>Frontier Web Services</2>",
    "language.chooser.ar": "عربى",
    "language.chooser.en-US": "English",
    "language.chooser.fr-FR": "Français",
    "profile.menu.profile": "Profil",
    logout: "Déconnexion",
    "errors.general": "Désolé, il y a eu une erreur!",
    "errors.required.fields": "Veuillez compléter les informations requises!",
    save: "Sauvegarder",
    "file.format": "Format du fichier",
    back: "Retour",
    download: "Télécharger",
    delete: "Supprimer",
    edit: "Éditer",
    restore: "Restaurer",
    "edit.document.remove.existing.file": "Supprimer le fichier actuel",
    "document.deleted.message":
      "Ce document a été supprimé, cliquez sur «Restaurer» pour le récupérer",
    "delete.modal.title": "Veuillez confirmer",
    "delete.modal.body": "Voulez-vous vraiment supprimer ce document?",
    cancel: "Annuler",
    "deleted.documents": "Documents supprimés",
    "welcome.user": "Bienvenue {{displayName}}!",
    ok: "Ok",
    "invalid.date": "Format de date non valide",
    search: "Rechercher",
    "no.result":
      "Aucun résultat trouvé, veuillez essayer différents mots clés.",
    "deleted.documents.no.documents": "Aucun document.",
    "drawer.advanced.search": "Recherche Avancée",
    "search.searchAll": "Entrez un mot-clé",
    "advanced.search.title": "Recherche Avancée",
    "show.more": "Afficher plus",
    "client.info.zg": "",
    "client.info.presidency": "La présidence",
    "client.info.upr": "UPR",
    attachments: "Pièces jointes",
    "no.attachments": "pas de pièces jointes",
    "add.attachments": "Ajouter des pièces jointes",
    "add.attachments.title": "Ajouter des pièces jointes",
    "view.attahcment.title": "Pièce jointe: {{title}}",
    "alerts.attachments.saved": "Pièces jointes enregistrées avec succès",
    "click.to.download": "Cliquez pour télécharger",
    "client.info.dev": "Application expérimentale",
    "client.info.panpa": "Le Port Autonome de Nouakchott",
    signin: "Se connecter",
    "email.address": "Adresse e-mail",
    password: "Mot de passe",
    "create.account.text": "Vous n'avez pas de compte ?",
    "create.account.link": "S'inscrire",
    "forgot.passowrd": "Mot de passe oublié?",
    "already.have.an.account.text": "Vous avez déjà un compte?",
    "already.have.an.account.link": "Se connecter",
    "sign.up": "S'inscrire",
    "confirm.password": "Confirmez le mot de passe",
    "last.name": "Nom de famille",
    "first.name": "Prénom",
    "full.name": "Nom et prénom",
    "confirm.sign.up": "Confirmer l'inscription",
    "sign.up.code": "Code",
    InvalidPasswordException: "Le mot de passe ñ'est pas conforme au format.",
    CodeMismatchException:
      "Code de vérification fourni non valide, veuillez réessayer.",
    "request.new.password.code": "Envoyez-moi le code de vérification",
    "check.email.for.code":
      "Veuillez consulter votre e-mail pour le code de réinitialisation du mot de passe et entrez-le ci-dessous.",
    "create.new.password": "Créer un nouveau mot de passe",
    "password.rest.instructions":
      "Veuillez fournir votre adresse e-mail, nous vous enverrons un code de vérification pour réinitialiser votre mot de passe.",
    "confirm.sign.up.instructions":
      "Veuillez consulter votre e-mail pour le code de vérification et entrez-le ci-dessous pour terminer votre inscription.",
    "all.fields.required": "Tous les champs sont requis.",
    "password.mismatch":
      "Veuillez vérifier les mots de passe que vous avez entrés.",
    "password.instructions":
      "Exigences de mot de passe : minimum de 8 caractères, au moins un chiffre, une lettre majuscule et une lettre minuscule.",
    "email.required": "Adresse e-mail est nécessaire.",
    UsernameExistsException:
      "Un compte associé à l'e-mail indiqué existe déjà, veuillez vous connecter.",
    UserNotFoundException: "L'utilisateur n'existe pas.",
    NotAuthorizedException: "Identifiant ou mot de passe incorrect.",
    NetworkError:
      "Désolé, il y a eu une erreur de connexion, veuillez réessayer.",
    "account.pending": "Compte en attente d'approbation",
    "account.pending.instructions":
      "Votre compte a été créé avec succès, vous pourrez vous connecter après approbation par l'administrateur de votre site.",
    "try.again": "Réessayer",
    "user.list": "liste d'utilisateur",
    "pending.users.list": "Utilisateurs inactifs",
    "active.users.list": "Éditeurs/Éditrices",
    "admin.users.list": "Administrateurs/Administratrices",
    "see.more": "Voir plus",
    "user.column.userStatus": "Statut de l'utilisateur",
    "user.column.email": "Adresse e-mail",
    "user.column.fullName": "Nom",
    "make.regular": "Passer à l'éditeur",
    "make.admin": "Passer en administrateur",
    "disable.user": "Désactiver l'utilisateur",
    "enable.user": "Activer l'utilisateur",
    UNCONFIRMED: "Non confirmé",
    CONFIRMED: "Confirmé",
    FORCE_CHANGE_PASSWORD: "Non confirmé",
    "manage.users": "Gérer les utilisateurs",
    "pending.users.list.desc":
      "Il s'agit d'une liste d'utilisateurs qui se sont récemment inscrits, mais qui doivent encore être confirmés par un administrateur, et de ceux qui ont été désactivés par les administrateurs.",
    "active.users.list.desc":
      "Les éditeurs peuvent modifier tout le contenu du site Web, mais ne pourront pas apporter de modifications aux autres utilisateurs.",
    "admin.users.list.desc":
      "Les administrateurs peuvent faire tout ce que les éditeurs peuvent faire et ils peuvent gérer d'autres utilisateurs.",
    "user.list.empty":
      "Vous n'avez actuellement aucun autre utilisateur, une fois les utilisateurs inscrits, ils seront répertoriés ici.",
    "ERROR_auth/invalid-credential": "Authentification invalide",
    "ERROR_auth/weak-password": "Mot de passe faible",
    "ERROR_auth/expired-action-code": "Le code a expiré.",
    "forgot.password": "Mot de passe oublié?",
    "remember.me": "Souviens-toi de moi",
    "verify.email": "Bienvenue à bord - Plus qu'un petit pas à faire !",
    "verify.email.unverified":
      "Bienvenue dans l'équipe! Pour sécuriser votre compte et débloquer toutes nos fonctionnalités géniales, veuillez vérifier votre adresse e-mail. Nous vous avons envoyé un e-mail rapide avec des instructions – vérifiez votre boîte de réception maintenant.",
    "reset.password": "Réinitialiser le mot de passe",
    "reset.password.instructions":
      "Veuillez fournir votre adresse e-mail, nous vous enverrons un lien de vérification pour réinitialiser votre mot de passe.",
    "reset.password.send.link.button":
      "Instructions pour réinitialiser mot de passe",
    "reset.password.email.sent.success.instructions":
      "Super! un e-mail contenant des instructions sur la façon de réinitialiser votre mot de passe a été envoyé à l'adresse e-mail que vous avez fournie.",
    "create.new.password.instructions":
      "Saisissez un nouveau mot de passe ci-dessous pour le compte associé à l'adresse e-mail {{email}}.",
    "create.new.password.error":
      "Oh non ! Le lien n'est pas valide. Veuillez réessayer de réinitialiser votre mot de passe.",
    "auth/invalid-action-code":
      "Veuillez réessayer, le lien de vérification n'est plus valide.",
    "verify.email.success":
      "Merci d'avoir vérifié votre adresse e-mail, cliquez sur le lien ci-dessous pour accéder à votre compte.",
    "verify.email.resend.success":
      "Veuillez vérifier votre messagerie électronique pour un nouveau lien afin de vérifier votre adresse e-mail. Si vous ne le trouvez pas, veuillez consulter votre dossier spam.",
    "verify.email.instructions":
      "Bienvenue à nouveau ! Pour garantir une expérience sécurisée et personnalisée, veuillez cliquer sur le bouton ci-dessous pour vérifier votre adresse e-mail.",
    "verify.email.button": "Vérifiez votre adresse e-mail !",
    "verify.email.resend": "Envoyez-moi un nouveau lien ! ",
    "access.dashboard": "Accéder à l'application",
    "create.new.password.success":
      "Super ! Votre mot de passe a été mis à jour avec succès. ",
    organizations: "Entreprises",
    "my.organizations.add": "Ajouter une nouvelle entreprise",
    "item.delete.one.success": "Un élément a été supprimé avec succès !",
    "item.delete.multiple.success":
      "{{number}} éléments ont été supprimés avec succès !",
    "department.add.success": "Département ajouté avec succès !",
    "document.type.add.success": "Type de document ajouté avec succès !",
    "document.type.delete.success": "Type de document supprimé avec succès !",
    "general.no.data": "Aucune donnée.",
    departments: "Départements",
    "new.department": "Ajouter un nouveau département",
    "enter.new.department": "Entrez un nouveau département",
    "enter.new.document.type": "Entrez un nouveau type de document",
    "add.new.org": "Ajout d'une nouvelle entreprise",
    "add.new.org.heading": "Prêt à ajouter une nouvelle entreprise ?",
    "add.new.org.subheading":
      "Fournissez des informations sur votre entreprise ci-dessous.",
    "add.new.org.form.name": "Nom (Obligatoire)",
    "add.new.org.form.email": "Adresse e-mail",
    "add.new.org.form.phone": "Numéro de téléphone",
    "add.new.org.form.website": "Site Web",
    "add.new.org.form.contact.person": "Responsable",
    "add.new.org.form.about": "À propos",
    "add.new.org.form.button": "Ajouter une entreprise",
    "org.enter.valid.name": "Saisissez un nom valide",
    "view.org.subheading": "Informations",
    "drag.drop.instructions":
      "Glissez-déposez des fichiers ici, ou cliquez pour sélectionner des fichiers",
    "drag.drop.instructions.new.org":
      "(Seules les images *.jpeg et *.png seront acceptées)",
    "drag.drop.dropping": "Déposez les fichiers ici...",
    "file-too-large": "Erreur : fichier trop volumineux.",
    "file-invalid-type": "Erreur : type de fichier invalide.",
    "org.saved.success":
      "Informations de l'entreprise enregistrées avec succès",
    "update.org": "Mettre à jour les informations de l'entreprise",
    "update.org.heading":
      "Prêt à mettre à jour les informations de votre entreprise ?",
    "update.org.subheading":
      "Mettez à jour les informations de votre entreprise ci-dessous",
    "update.org.button": "Mettre à jour les infos",
    logo: "Logo",
    "ERROR_auth/network-request-failed": "Erreur de réseau",
    "ERROR_auth/too-many-requests":
      "L'accès à ce compte a été temporairement désactivé en raison de nombreuses tentatives de connexion échouées. Vous pouvez le restaurer immédiatement en réinitialisant votre mot de passe ou vous pouvez réessayer plus tard.",
    "password.required": "Le mot de passe est requis",
    "users.list.page": "Utilisateurs",
    account: "Compte",
    settings: "Paramètres",
    "select.an.avatar": "Sélectionnez un avatar pour votre entreprise",
    "or.upload.logo": "Ou téléchargez le logo de votre entreprise",
    refresh: "Actualiser",
    "update.available":
      "Une nouvelle mise à jour de l'application est disponible",
    "update.available.desc":
      " Appuyez sur `Actualiser` pour obtenir la dernière mise à jour, ou cliquez sur `Annuler` pour ignorer cette mise à jour.",
    "add.new.doc": "Ajouter un nouveau document",
    "add.new.doc.heading": "Ajout d'un nouveau document",
    "add.new.doc.subheading":
      "Fournissez les informations du document ci-dessous.",
    "add.new.doc.form.button": "Enregistrer les modifications",
    "add.new.doc.form.department.select": "Département",
    "add.new.doc.form.doc.type": "Type de document",
    "add.new.doc.form.note": "Une note optionnelle",
    "add.new.doc.form.date.recieved": "Date de réception",
    active: "Actif",
    "upload.files": "Télécharger des fichiers",
    "select.department": "Sélectionnez un département",
    "doc.enter.valid.department": "Erreur : un département est requis.",
    "select.document.type": "Sélectionnez un type de document",
    "doc.enter.valid.doc.type": "Erreur : un type de document est requis.",
    "file.and.size.label": "{{name}} - {{size}} Ko.",
    inactive: "Inactif",
    documents: "Documents",
    "view.pdf": "Télécharger",
    update: "Mettre à jour",
    "update.doc": "Mise à jour du document",
    "update.doc.heading": "Mettre à jour les informations du document",
    "update.doc.subheading":
      "Apportez des modifications ci-dessous et cliquez sur `Mettre à jour`",
    "doc.saved.success": "Informations du document enregistrées avec succès.",
    "update.document.upload.warning.title":
      "Impossible de télécharger des images/scans supplémentaires",
    "update.document.upload.warning.description":
      "Les images/scans ne peuvent être ajoutés que lors de la création d'un nouveau document.",
    "error.code.404": "404",
    "error.message.404": "Oups, une erreur est survenue.",
    "error.message.404.details":
      "Désolé, nous n'avons pas pu trouver votre page.",
    "common.back.to.home": "Retour à la page d'accueil",
    "error.message.403": "Interdit",
    "error.message.403.details":
      "Vous n'avez pas la permission d'accéder à cette ressource.",
    "error.message.500": "Erreur interne du serveur",
    "error.message.500.details":
      "Le serveur a rencontré une erreur inattendue. Veuillez réessayer plus tard.",
    "new.document.header.link": "Nouveau document",
    "current.org.is": "L'entreprise actuelle est: {{name}}",
    "orgs.switch": "Voir toutes les entreprises",
    "choose.a.date.range": "Sélectionnez une plage de dates",
    "no.results": "Aucun résultat trouvé.",
    "no.results.action": "Affinez votre recherche.",
    "view.all.documents": "Afficher tous les documents",
    "must.include.at.least.one.file": "Doit inclure au moins un fichier",
    "add.type": "Aj. type",
    "add.department": "Aj. département",
    "user.ban": "Bannir",
    "user.deactivate": "Désactiver",
    "user.activate": "Activer",
    "user.make.admin": "Nommer administrateur",
    "user.make.editor": "Nommer éditeur",
    admin: "Administrateur",
    editor: "Éditeur",
    pending: "En attente",
    banned: "Banni",
    unknown: "Inconnu",
    "delete.document": "Confirmer la deletion du document",
    "delete.document.description":
      "Une fois qu'un document est supprimé, il ne peut pas être restauré.",
    "release.date": "Publication {{date}}",
    "I accept the<1>Terms and Conditions</1>":
      "J'accepte les <1>termes et conditions</1>",
    "theme.switcher.light": "Mode clair",
    "theme.switcher.dark": "Mode sombre",
    "theme.switcher.system": "Mode système",
    "ERROR_auth/firebase-app-check-token-is-invalid.":
      "Non autorisé : Jeton de sécurité invalide.",
    archived: "archivé",
    next: "Suivant",
    previous: "Précédent",
    organization: "Entreprise",
  },
};
