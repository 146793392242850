import { ReactNode, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// hooks
import useAuth from "~hooks/useAuth";

import { PATH_AUTH } from "~routes/paths";

// types
import { UserStatus } from "~types/data";

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isInitialized, user, profile } = useAuth();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = user !== null;
  const pending = profile?.status === UserStatus.Pending;
  const unverified = isInitialized && isAuthenticated && !user.emailVerified;

  useEffect(() => {
    if (!isAuthenticated && isInitialized) {
      navigate(`${PATH_AUTH.signIn}?next=${encodeURIComponent(pathname)}`);
    }
  }, [isInitialized, navigate, pathname, isAuthenticated]);

  useEffect(() => {
    if (unverified) {
      navigate(
        `${PATH_AUTH.unverifiedEmail}?next=${encodeURIComponent(pathname)}`,
      );
    } else if (pending) {
      navigate(PATH_AUTH.pending);
    }
  }, [unverified, pending, navigate, pathname, isInitialized]);

  return <>{children}</>;
}
