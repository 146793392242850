import { ReactNode, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// hooks
import useAuth from "~hooks/useAuth";
// routes
// components
import LoadingScreen from "~components/LoadingScreen";
//routes/paths
import { PATH_AUTH } from "~routes/paths";

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isInitialized, user } = useAuth();
  const [searchParams] = useSearchParams();

  const isAuthenticated = user !== null;

  const next = searchParams.get("next") || "/";

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      if (!user?.emailVerified) {
        navigate(
          `${PATH_AUTH.unverifiedEmail}?next=${encodeURIComponent(next)}`,
        );
      } else {
        navigate(next);
      }
    }
  }, [isAuthenticated, navigate, next, user?.emailVerified]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
