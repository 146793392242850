import {
  Transition,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import {
  MoonIcon,
  SunIcon,
  ComputerDesktopIcon,
} from "@heroicons/react/24/outline";
import { themeOptions } from "~/config";
import useLocales from "~hooks/useLocales";

import useThemeSwitcher from "~hooks/useThemeSwitcher";

const iconMap: Record<
  (typeof themeOptions)[number],
  React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >
> = {
  light: SunIcon,
  dark: MoonIcon,
  system: ComputerDesktopIcon,
};

export default function LanguageDropdown() {
  const { t } = useLocales();
  const { theme, onThemeChange } = useThemeSwitcher();

  return (
    <Listbox value={theme} onChange={onThemeChange}>
      <ListboxButton
        className={
          "relative block bg-white py-1.5 pl-2 pr-2 text-left  text-sm/6 focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25 dark:bg-slate-900"
        }
      >
        <span className="dark:hidden">
          <SunIcon className="inline w-5" />
        </span>
        <span className="hidden dark:inline">
          <MoonIcon className="inline w-5" />
        </span>{" "}
      </ListboxButton>
      <Transition
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ListboxOptions
          anchor="bottom"
          className="dark:highlight-white/5 absolute z-50 w-40 overflow-hidden rounded-lg bg-white py-1 text-sm font-semibold text-neutral-900 shadow-lg ring-1 ring-slate-900/10 dark:bg-slate-800 dark:text-white dark:ring-0"
        >
          {themeOptions.map((mode) => {
            const Icon = iconMap[mode];
            return (
              <ListboxOption
                key={mode}
                value={mode}
                className={`${mode === theme ? "text-sky-500" : ""} group flex cursor-default select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white/10`}
              >
                <Icon className="mr-2 inline w-5 " />
                <div className="text-sm/6">{t("theme.switcher." + mode)}</div>
              </ListboxOption>
            );
          })}
        </ListboxOptions>
      </Transition>
    </Listbox>
  );
}
