import { doc } from "firebase/firestore";
import { useMemo } from "react";

import { DB } from "~backend/firebase";
import { useRealTimeDoc } from "./useRealTimeQuery";
import getQueryKeys from "~utils/get-query-keys";

function getAutoCompleteDetailsQueryKeys(id: string, collectionName: string) {
  if (id === "-1") {
    return [""];
  }
  const keys = getQueryKeys(collectionName);
  return keys.detail(id);
}

export function useDoc<T>(id: string, collectionName: string) {
  const key = useMemo(() => {
    return getAutoCompleteDetailsQueryKeys(id, collectionName);
  }, [collectionName, id]);

  const query = useMemo(() => {
    return doc(DB, collectionName, id);
  }, [collectionName, id]);
  return useRealTimeDoc<T>(key, query);
}
