import { LoaderFunctionArgs } from "react-router-dom";
import { InitialDocument } from "~types/data";

export type FormData = Omit<InitialDocument, "dateReceived"> & {
  dateReceived: {
    startDate: string | Date | null;
    endDate: string | Date | null;
  } | null;
  orderBy?: string;
};

export type LoaderRetuurnType = {
  data: FormData;
  pagination: {
    page: number;
    perPage: number;
  };
};

const PAGE_SIZE = 24;

const loader = ({ request }: LoaderFunctionArgs): LoaderRetuurnType => {
  const url = new URL(request.url);
  const dateRange = url.searchParams.get("dateRange");
  const department = url.searchParams.get("department") || "";
  const docType = url.searchParams.get("docType") || "";
  const archived = url.searchParams.get("archived");
  const orderBy = url.searchParams.get("orderBy");
  const page = parseInt(url.searchParams.get("page") || "");
  const perPage = parseInt(url.searchParams.get("perPage") || "");
  let startDate = null;
  let endDate = null;
  if (dateRange) {
    [startDate, endDate] = dateRange.split(" ~ ").map((str) => new Date(str));
  }

  return {
    data: {
      department,
      docType,
      orderBy: orderBy || "created",
      active: archived !== "on",
      dateReceived: {
        startDate,
        endDate,
      },
    },
    pagination: {
      page: isNaN(page) ? 0 : page,
      perPage: isNaN(perPage) ? PAGE_SIZE : perPage,
    },
  };
};

export default loader;
