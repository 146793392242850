import { LoaderFunction } from "react-router-dom";
import { applyActionCode, verifyPasswordResetCode } from "firebase/auth";
import { AUTH as auth } from "~backend/firebase";

const modes = ["verifyEmail", "resetPassword"];

export const verifyLoader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const actionCode = url.searchParams.get("oobCode") || "";
  const mode = url.searchParams.get("mode") || "";
  if (!modes.includes(mode)) {
    return {
      error: "invalid_mode",
    };
  }

  try {
    let emailAddress;
    if (mode === "verifyEmail") {
      await applyActionCode(auth, actionCode);
      return {
        mode,
        actionCode,
      };
    } else {
      emailAddress = await verifyPasswordResetCode(auth, actionCode);
      return {
        mode,
        actionCode,
        emailAddress,
      };
    }
  } catch (e) {
    return {
      error: "invalid_code",
      actionCode,
      mode,
    };
  }
};
